<template>
  <div class="portfolio-container">
    <h1>Currently powering</h1>
    <div class="project-container">
      <div class="project" v-for="project in projects" :key="project.id">
        <div class="title" @click="openInNewTab(project.projectUrl)">
          {{ project.title }}
        </div>
        <div @click="openInNewTab(project.projectUrl)" class="image-container">
          <img :src="project.thumbnailUrl" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    const projects = [
      {
        id: 1,
        title: 'Gavi Wine Restaurant',
        thumbnailUrl: require('@/assets/customer/Gavi.png'),
        projectUrl: 'https://www.gaviwinerestaurant.com/',
      },
      {
        id: 2,
        title: 'Anna Banana Baking',
        thumbnailUrl: require('@/assets/customer/Anna.png'),
        projectUrl: 'https://www.annabananabakery.ca/',
      },
    ];

    const openInNewTab = (url) => {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    };
    return { openInNewTab, projects };
  },
};
</script>

<style scoped>
.portfolio-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  padding-bottom: 4rem;
}
h1 {
  font-size: 2rem;
  margin: 2rem 0;
}
.project-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.project {
  width: 400px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 1rem;
  border-radius: 25px;
  padding: 1rem;
  background-color: var(--background);
}

.project .image-container {
  width: 350px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0;
  border-radius: 25px;
  /* -webkit-box-shadow: 0px 0px 13px 1px var(--accent);
  box-shadow: 0px 0px 13px 1px var(--accent); */
}

.project img {
  width: 100%;
  height: 100%;
  margin-top: 1rem;
  border-radius: 25px;
  cursor: pointer;
  margin: 0;
}
.title {
  font-size: 1.5rem;
  cursor: pointer;
}

/* Media Styles */
@media (max-width: 768px) {
  .project-container {
    flex-direction: column;
    align-items: center;
  }
  .project {
    width: 80%;
    margin-bottom: 3rem;
  }

  h1 {
    font-size: 1.5rem;
  }
  .title {
    font-size: 1.25rem;
  }
  img {
    width: 100%;
    height: auto;
  }
}
</style>
