<template>
  <div class="home">
    <div class="hero-container">
      <h1>Affordable Websites, Big Results</h1>
      <p>I create simple sophisticated Websites, and I love what I do.</p>
      <img src="@/assets/hero.png" alt="" />
    </div>
    <div class="introduction-container">
      <div class="introduction">
        <h2>Hi, I'm Matthias. Nice to meet you.</h2>
        <p>
          Nothing has excited me more than the idea of creating something new
          and web development has given me the opportunity to do just that. Are
          you looking for a website that is both affordable and effective? I can
          help you with that. I specialize in creating websites that are simple,
          sophisticated, and easy to use and I look forward to working with you!
        </p>
      </div>
    </div>
    <!-- <Portfolio :projects="projects" /> -->
    <Customers />
  </div>
</template>

<script>
import getCollection from '@/composables/getCollection';
import Portfolio from '@/components/portfolio/Portfolio.vue';
import Customers from '@/components/portfolio/Customers.vue';
import { ref } from 'vue';
export default {
  components: {
    Portfolio,
    Customers,
  },
  name: 'HomeView',

  setup() {
    const { documents: projects } = getCollection('projects');

    return { projects };
  },
};
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
p {
  margin-top: 1rem;
  font-size: 1.5rem !important;
}
.hero-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 3rem;
  padding-bottom: 0px;
}

img {
  height: 234px;
  width: 234px;
  margin-top: 160px;
  margin-bottom: 80px;
}

.introduction-container {
  width: 100vw;
  padding: 3rem 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
}

.introduction-container h2 {
  font-size: 2rem !important;
}
.introduction-container p {
  font-size: 1.25rem !important;
}
.introduction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 800px;
}
.introduction p {
  text-align: left;
}

/* Media Styles */
@media (max-width: 768px) {
  img {
    height: 150px;
    width: 150px;
  }
  .hero-container {
    padding: 2rem 2rem;
    text-align: center;
  }

  h1 {
    font-size: 2rem !important;
  }
  .introduction p {
    font-size: 1.25rem !important;
    padding: 1rem;
    text-align: center;
  }
}
</style>
